<template>
  <v-main class="users">
    <div class="app-container pt-10 pb-3">
      <section
        class="users__tool-bar d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="users__tool-bar__info">
          <h1 class="text-h4 font-weight-bold">
            {{ pageInfo.title }}
          </h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            {{ pageInfo.description }}
          </p>
        </div>

        <div class="d-flex justify-end align-center">
          <a
            v-if="!isMobile"
            class="users__tool-bar-filter-btn d-flex align-center justify-center"
            :class="{ 'users__tool-bar-filter-btn--active': isFilterActive }"
            @click="handleFilterClick"
          >
            <v-icon color="black">mdi-filter-variant</v-icon>
          </a>
          <v-badge
            v-else
            :value="filterBadgeContent"
            :content="filterBadgeContent"
            offset-x="15"
            offset-y="20"
            color="primary"
          >
            <a
              class="users__tool-bar-filter-btn d-flex align-center justify-center"
              @click="handleFilterClick"
            >
              <v-icon color="black">mdi-filter-variant</v-icon>
            </a>
          </v-badge>

          <v-btn
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-3"
            @click="inviteUserModal = true"
          >
            Invite new user
          </v-btn>
        </div>
      </section>

      <section class="users__table">
        <v-skeleton-loader
          v-if="firstLoading"
          :loading="loading"
          type="table"
        />
        <v-data-table
          v-if="!firstLoading"
          id="users-table"
          :headers="tableHeaders"
          :items="clinicians"
          :loading="loading"
          :mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="totalClinicians"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          multi-sort
          @update:options="handleTableUpdate"
        >
          <template v-if="isFilterActive && !isMobile" v-slot:top>
            <v-row dense>
              <v-col class="text-h6"> Filters </v-col>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <v-col md="4" class="pl-0">
                <search-input
                  :value="filters.name"
                  label="Enter user name"
                  @change="handleFiltersChange($event, 'name')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters.verificationStatus"
                  :items="statusItems"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="title"
                  item-value="value"
                  label="Status"
                  hide-details
                  clearable
                  outlined
                  @change="handleFiltersChange($event, 'verificationStatus')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters.role"
                  :items="roleOptions"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Role"
                  hide-details
                  clearable
                  outlined
                  @change="handleFiltersChange($event, 'role')"
                />
              </v-col>
              <v-col>
                <a @click="handleFilterMenuCancel">
                  <v-icon color="black">mdi-close</v-icon>
                </a>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item="{ item }">
            <tr @click="handleRowClick(item)">
              <td>{{ item.name }}</td>
              <td>
                <div class="d-flex flex-start align-center">
                  <v-chip
                    small
                    label
                    :color="VerificationStatusColors[item.verificationStatus]"
                  >
                    {{ UserStatusesForView[item.verificationStatus] || "-" }}
                  </v-chip>
                </div>
              </td>
              <td>
                <div class="d-flex flex-start align-center">
                  <v-chip :key="i" v-for="(roles, i) in item.allRoles" small outlined class="mr-1">
                    {{ roles }}
                  </v-chip>
                </div>
              </td>
              <td>
                <div class="d-flex flex-start align-center">
                  <span>{{
                    item.lastActivity
                      ? dateToLocalString(item.lastActivity, false)
                      : "-"
                  }}</span>
                </div>
              </td>
              <td>
                <span>{{ item?.deactivatedAt ? "Inactive" : "Active" }}</span>
              </td>
              <td>
                <div
                  :ref="`container-${item._id}`"
                  class="d-flex justify-start"
                >
                  <v-btn
                    v-for="(actionItem, i) in getUserManagementActions(item)"
                    :key="i"
                    small
                    icon
                    class="mr-1"
                    @click.stop="actionItem.action"
                  >
                    <v-icon>{{ actionItem.icon }}</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          v-model="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>

      <v-dialog v-if="isMobile" v-model="isFilterActive" fullscreen persistent>
        <v-card flat class="users__filter-menu">
          <div
            class="app-container pb-4 d-flex flex-column justify-space-between"
          >
            <div class="users__filter-menu__filters pt-10">
              <h1 class="text-h4 font-weight-bold pb-5">Filters</h1>

              <search-input
                :value="filters.name"
                label="Enter user name"
                @change="handleFiltersChange($event, 'name')"
              />
              <br />
              <v-select
                :value="filters.verificationStatus"
                :items="statusItems"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="title"
                item-value="value"
                label="Status"
                hide-details
                full-width
                clearable
                outlined
                @change="handleFiltersChange($event, 'verificationStatus')"
              />
              <br />
              <v-select
                :value="filters.role"
                :items="rolesItems"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Role"
                hide-details
                full-width
                clearable
                outlined
                @change="handleFiltersChange($event, 'role')"
              />
            </div>

            <div class="users__filter-menu__controls d-flex flex-column">
              <v-btn
                :disabled="!filterBadgeContent"
                color="primary"
                rounded
                large
                variant="elevated"
                class="mb-5"
                @click="handleFilterClick"
              >
                Apply
              </v-btn>

              <v-btn
                color="primary"
                rounded
                large
                text
                class="text-subtitle-1"
                @click="handleFilterMenuCancel"
              >
                Cancel
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <invite-new-user-modal
      ref="inviteUserModal"
      :dialog="inviteUserModal"
      :user-role-items="inviteRoleOptions"
      :loading="inviteUserLoading"
      @submit="handleInviteUser"
      @toggle="handleInviteModalToggle"
    />

    <edit-user-data-modal
      :dialog="userEditModal"
      :user-roles-items="roleOptions"
      :user="editableUser"
      :loading="editUserLoading"
      @toggle="handleEditUserToggle"
      @submit="handleUserUpdate"
      @invite-update="handleUpdateInvite"
    />

    <user-action-modal
      :dialog="adminActionModal"
      :action-type="adminAction"
      @toggle="handleAdminManagementModal"
      @submit="handleAdminActionSubmit"
    />
  </v-main>
</template>

<script>
import InviteNewUserModal from "@/components/users/InviteNewUserModal.vue";
import SearchInput from "@/components/controls/SearchInput.vue";
import EditUserDataModal from "@/components/users/EditUserDataModal.vue";
import UserActionModal from "@/components/users/UserActionModal.vue";
import {
  TableRowsPerPage,
  UserStatusesForView,
  VerificationStatusColors,
  AdminManagementActions,
  UserRoles,
} from "@/misc/constants";
import {
  getUsers,
  updateUserById,
  activateUser,
  deactivateUser,
} from "@/services/users";
import { inviteNewUser, updateInviteUser } from "@/services/auth";
import dateToLocalString from "@/helpers/dateToLocalString";

export default {
  name: "UsersPage",

  components: {
    SearchInput,
    InviteNewUserModal,
    EditUserDataModal,
    UserActionModal,
  },

  data() {
    const tableHeaders = [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Status",
        value: "verificationStatus",
        sortable: true,
      },
      {
        text: "Role",
        value: "role",
        sortable: false,
      },
      {
        text: "Last Activity",
        value: "lastActivity",
        sortable: true,
      },
      {
        text: "Activation status",
        value: "deactivatedAt",
        sortable: true,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ];
    const pageInfo = {
      title: "Users",
      description: "Here you can invite new users or manage existing.",
    };
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    const statusItems = Object.keys(UserStatusesForView).map((key) => ({
      title: UserStatusesForView[key],
      value: key,
    }));

    const roleOptions = Object.values(UserRoles).map((role) => ({
      text: role[0].toUpperCase() + role.slice(1).toLowerCase(),
      value: role,
    }));

    const inviteRoleOptions = roleOptions.filter((role) => role.value !== UserRoles.doctor);

    return {
      options: {},
      totalClinicians: null,
      loading: false,
      firstLoading: true,
      inviteUserLoading: false,
      editUserLoading: false,
      pageInfo,
      tableHeaders,
      clinicians: [],
      isFilterActive: false,
      filters: {
        name: null,
        verificationStatus: null,
        role: null,
      },
      tableFooterOptions,
      VerificationStatusColors,
      UserStatusesForView,
      statusItems,
      editableUser: {},
      userEditModal: false,
      inviteUserModal: false,
      adminActionModal: false,
      roleOptions,
      inviteRoleOptions,
      adminAction: null,
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    filterBadgeContent() {
      return Object.values(this.filters).reduce(
        (acc, value) => acc + Number(!!value),
        0
      );
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalClinicians / this.options.itemsPerPage);
    },
  },

  async mounted() {
    await this.fetchUsers();
  },

  methods: {
    dateToLocalString,
    async fetchUsers() {
      this.loading = true;
      try {
        const query = this.buildQuery();
        const { data, total } = await getUsers(query);
        this.clinicians = data;
        this.totalClinicians = total;
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        page: page || 1,
        pageSize: itemsPerPage || 10,
        sort: "createdAt:-1",
        groupedMemberships: true,
      };

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = `${sort},${queryParams.sort}`;
      }

      if (Object.values(this.filters).some((val) => !!val === true)) {
        const filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "name") {
              filters[key] = { $regex: this.filters[key], $options: "i" };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
        queryParams.filter = filters;
      }

      return queryParams;
    },
    handleFilterClick() {
      this.isFilterActive = !this.isFilterActive;
    },
    handleRowClick(item) {
      this.editableUser = item;
      this.userEditModal = true;
    },
    async handleFiltersChange(value, key) {
      this.filters[key] = value;
      this.options.page = 1;
      await this.fetchUsers();
    },
    async handleFilterMenuCancel() {
      this.handleFilterClick();
      this.filters.name = null;
      this.filters.role = null;
      this.filters.verificationStatus = null;
      await this.fetchUsers();
    },
    async handleTableUpdate() {
      await this.fetchUsers();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    handleEditUserToggle(value) {
      this.userEditModal = value;
    },
    handleInviteModalToggle(value) {
      this.inviteUserModal = value;
    },
    handleAdminManagementModal(value) {
      this.adminActionModal = value;
    },
    async handleInviteUser(data) {
      this.inviteUserLoading = true;
      try {
        const inviteNewUserResponse =  await inviteNewUser(data);
        await this.fetchUsers();
        this.$notify({
          type: "success",
          title: "Invite process",
          text: inviteNewUserResponse.isNewUser
            ? 'New user has been invited, invitation link sent'
            : 'The user has been successfully added to the account',
        });
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.inviteUserLoading = false;
        this.handleInviteModalToggle(!this.inviteUserModal);
        this.$refs.inviteUserModal.resetData();
      }
    },
    async handleUserUpdate({ id, body }) {
      this.editUserLoading = true;
      try {
        await updateUserById(id, body);
        this.$notify({
          type: "success",
          title: "Update user",
          text: "User data is successfully updated",
        });
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.editUserLoading = false;
        this.handleEditUserToggle(!this.userEditModal);
        await this.fetchUsers();
      }
    },
    async handleUpdateInvite({ id }) {
      this.editUserLoading = true;
      try {
        await updateInviteUser({ userId: id });
        this.$notify({
          type: "success",
          title: "Update user",
          text: "Sent updated invitation link",
        });
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.editUserLoading = false;
        this.handleEditUserToggle(!this.userEditModal);
        await this.fetchUsers();
      }
    },
    async handleAdminActionSubmit(callback) {
      try {
        const messageInfo = {};
        if (!this.editableUser) return;
        const { _id } = this.editableUser;
        switch (this.adminAction) {
          case AdminManagementActions.activateUser:
            messageInfo.title = "Activate user";
            messageInfo.text = "User is successfully activated";
            await activateUser(_id);
            break;
          case AdminManagementActions.deactivateUser:
            messageInfo.title = "Deactivate user";
            messageInfo.text = "User is successfully deactivated";
            await deactivateUser(_id);
            break;
        }
        this.$notify({
          type: "success",
          ...messageInfo,
        });

        await this.fetchUsers();
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        callback();
        this.adminActionModal = false;
      }
    },
    onUserActionClick(user, type) {
      this.adminAction = type;
      this.editableUser = user;
      this.adminActionModal = true;
    },
    getUserManagementActions(user) {
      const activationAction = user?.deactivatedAt
        ? {
            icon: "mdi-lock",
            text: "Activate user",
            action: () =>
              this.onUserActionClick(user, AdminManagementActions.activateUser),
          }
        : {
            icon: "mdi-lock-open-outline",
            text: "Deactivate user",
            action: () =>
              this.onUserActionClick(
                user,
                AdminManagementActions.deactivateUser
              ),
          };

      return [activationAction];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

.users {
  &__tool-bar {
    &-filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }

  &__filter-menu {
    & .app-container {
      height: 100vh;
      padding-top: #{$headerHeight};
    }
  }

  &__table {
    .v-tooltip__content {
      position: absolute !important;
    }
  }
}
</style>
