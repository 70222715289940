<template>
  <v-dialog
    :value="dialog"
    width="616"
    style="z-index: 20001"
    persistent
    @click:outside="toggleDialog"
  >
    <v-card class="user-edit-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0"> Edit user </v-card-title>

      <v-form class="mt-5">
        <v-text-field
          :value="name"
          :error-messages="nameErrors"
          type="text"
          clearable
          label="User name"
          outlined
          placeholder="Enter user name"
          class="mb-2"
          :disabled="isNameDisabled"
          @input="onTextFieldsInput($event, 'name')"
          @blur="$v.name.$touch()"
        />
        <v-text-field
          :disabled="isEmailDisabled"
          :value="email"
          :error-messages="emailErrors"
          type="email"
          clearable
          outlined
          label="Email address"
          class="mb-2"
          @input="onTextFieldsInput($event, 'email')"
          @blur="$v.email.$touch()"
        />
        <v-select
          :value="roles"
          :items="userRolesItems"
          :error-messages="rolesErrors"
          label="Select Role"
          :menu-props="{ bottom: true, offsetY: true }"
          clearable
          outlined
          multiple
          item-value="value"
          @change="onTextFieldsInput($event, 'roles')"
          @blur="$v.roles.$touch()"
        >
          <template v-slot:selection="{ item }">
            <v-chip>
              <span>{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-form>

      <v-card-actions class="pa-0 mt-4 d-flex justify-space-between">
        <v-btn
          v-if="!isEmailDisabled && user.email"
          :disabled="loading || isEmailChanged"
          @click="onUpdateInvite"
          color="primary"
          rounded
          >Update invite</v-btn
        >
        <div class="d-flex w-100 justify-end">
          <v-btn color="primary" :disabled="loading" text @click="onCancel">
            Cancel
          </v-btn>
          <v-btn
            :disabled="submitDisabled"
            :loading="loading"
            color="primary"
            rounded
            normal
            variant="elevated"
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { UserRoles, UserVerificationStatuses } from "@/misc/constants";
import isEqual from "lodash/isEqual";

export default {
  name: "UserEditModal",

  mixins: [validationMixin],

  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    user: {
      type: Object,
      required: true,
    },
    userRolesItems: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: null,
      name: null,
      roles: null,
    };
  },

  validations: {
    email: { required, email },
    name: { required, minLength: minLength(5) },
    roles: { required },
  },

  computed: {
    submitDisabled() {
      return (
        this.$v.$invalid ||
        !this.isFormDataChanged ||
        this?.user?.deactivatedAt !== null
      );
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      !this.$v.name.minLength &&
        errors.push("The name must have a minimum 5 characters");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    rolesErrors() {
      const errors = [];
      if (!this.$v.roles.$dirty) return errors;
      !this.$v.roles.required && errors.push("Role is required");
      return errors;
    },
    isFormDataChanged() {
      const textFields = ["name", "email"];
      if (!this.name || !this.email) return false;
      const isTextFieldsChanged = textFields.some(
        (key) => this[key] !== this.user[key]
      );
      return isTextFieldsChanged || this.isRoleChanged;
    },
    isRoleChanged() {
      return !isEqual(this.user?.roles, this.roles);
    },
    isEmailDisabled() {
      return (
        this.user?.verificationStatus === UserVerificationStatuses.emailVerified
      );
    },
    isNameDisabled() {
      return this.user?.allRoles?.includes(UserRoles.doctor);
    },
    isEmailChanged() {
      return this.user?.email !== this.email;
    }
  },

  watch: {
    dialog(newValue) {
      if (newValue) {
        this.initModalData();
      }
    },
  },

  methods: {
    onCancel() {
      this.resetData();
      this.toggleDialog();
    },
    toggleDialog() {
      this.$emit("toggle", !this.dialog);
    },
    onUpdateInvite() {
      this.$emit("invite-update", { id: this.user._id });
    },
    onSubmit() {
      if (!this.user || !this.isFormDataChanged) return;

      const updateTextFields = ["name", "email"];
      const textData = updateTextFields.reduce((acc, key) => {
        if (typeof this[key] === "string" && this[key] !== this.user[key]) {
          acc[key] = this[key];
        }
        return acc;
      }, {});

      this.$emit("submit", {
        id: this.user._id,
        body: {
          ...textData,
          ...(this.isRoleChanged ? { roles: this.roles } : {}),
        },
      });
    },
    onTextFieldsInput(value, key) {
      this[key] = value;
      this.$v[key].$touch();
    },
    resetData() {
      this.name = null;
      this.email = null;
      this.roles = null;
    },
    initModalData() {
      const { name, email, allRoles } = this.user;
      this.name = name;
      this.email = email;
      this.roles = allRoles;
    },
  },
};
</script>
