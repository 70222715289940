import { axiosInstance } from "../setupInterceptors";

const endpointUrl = '/auth';

export const inviteNewUser = (body) => {
  return axiosInstance
    .post(`${endpointUrl}/invite`, body)
    .then((response) => response.data);
};

export const updateInviteUser = (body) => {
  return axiosInstance
    .post(`${endpointUrl}/re-invite`, body)
    .then((response) => response.data);
};
